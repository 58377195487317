<template>
	<div class="develop">
		<img src="@/assets/pc/home/develop.png" alt="" />
		<div class="whiteTitle">发展历程</div>
		<div class="progress">
			<div class="year">2021</div>
			<div class="year">2020</div>
			<div class="year">2019</div>
			<div class="year">2018</div>
		</div>
		<div
			class="slide"
			@mousedown="getDownLocation"
			@mousemove="getMoveLocation"
			@mouseup="stopMove"
		>
			<div
				class="progressDetails"
				:style="{ 'margin-left': left + 'px' }"
			>
				<div
					class="item"
					v-for="(item, i) in progressDetailsList"
					:key="i"
				>
					<div class="detailsYear">{{ item.year }}</div>
					<div
						class="content"
						v-for="(items, index) in item.content"
						:key="index"
					>
						<div>{{ items.month }}</div>
						<div class="txt">{{ items.text }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			left: 0,
			isDown: false,
			up: 0,
			down: 0,
			progressDetailsList: [
				{
					year: '2021',
					content: [
						{
							month: 'Feb',
							text:
								'APP更新“真题练习”、“趣味学习”等功能，荣获2020年中国教育行业诚信标杆企业。',
						},
						{
							month: 'Oct',
							text: '“德语GO”正式上线',
						},
					],
				},
				{
					year: '2020',
					content: [
						{
							month: 'Jul',
							text:
								'成立数字化学习事业部。“日语GO”APP安卓端上线。学习打卡小程序“打脸吗”上线。',
						},
						{
							month: 'Dec',
							text:
								'APP更新“精品课”、“词汇本”、“每日一练”等功能。日语GO官网上线。',
						},
					],
				},
				{
					year: '2019',
					content: [
						{
							month: 'Dec',
							text:
								'日语班学习人次达到3000+ 。 首届日语班高考日语平均分达106分。荣获2018年度新时代杰出办学单位。',
						},
					],
				},
				{
					year: '2018',
					content: [
						{
							month: 'Jun',
							text:
								'创立初期，为响应普通高中课程改革的总体要求，聚趣教育日语教研团队完成了高考日语整体教学架构。',
						},
						{
							month: 'Aug',
							text: '聚趣教育官网上线。',
						},
						{
							month: 'Dec',
							text:
								'线下日语班学习人次达到1000+。荣获2020年度最佳教育新锐奖。',
						},
					],
				},
			],
		}
	},
	methods: {
		getDownLocation(e) {
			this.down = e.clientX
			this.isDown = true
		},
		getMoveLocation(e) {
			this.up = e.clientX
			console.log(this.isDown)
			if (this.isDown) {
				let distance = this.up - this.down
				this.left = distance
				console.log(distance)
			}
		},
		stopMove() {
			this.isDown = false
		},
	},
}
</script>

<style lang="scss" scoped>
.develop {
	position: relative;
	overflow: hidden;
	img {
		margin: 0 auto;
	}
	.whiteTitle {
		margin-top: 100px;
		font-size: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.whiteTitle::after {
		content: '';
		display: inline-block;
		width: 6px;
		height: 20px;
		background-color: #fff;
		margin-left: 20px;
	}
	.whiteTitle::before {
		content: '';
		display: inline-block;
		width: 6px;
		height: 20px;
		background-color: #fff;
		margin-right: 20px;
	}
	.progress {
		position: absolute;
		top: 200px;
		display: flex;
		color: #fff;
		// background: rgb(16, 19, 211);
		width: 681px;
		height: 100px;
		left: 0;
		right: 0;
		margin: auto;
		justify-content: space-between;
		font-size: 24px;
		font-weight: 400;
		.year {
			display: flex;
			justify-content: center;
		}
		.year::after {
			content: '';
			position: absolute;
			display: block;
			width: 23px;
			height: 23px;
			top: 38px;
			background-color: #fff;
			// z-index: 99;
			border-radius: 50%;
		}
	}
	.progress::after {
		content: '';
		position: absolute;
		display: block;
		width: 630px;
		height: 1px;
		background: #fff;
		top: 50px;
		left: 18px;
	}
	.slide {
		min-width: 1200px;
		max-width: 1600px;
		margin: auto;
		overflow: hidden;
		.progressDetails {
			box-sizing: border-box;
			margin: 48px 0 100px 0;
			margin-left: -200px;
			display: flex;
			min-width: 1200px;
			.item {
				margin-right: 30px;
				width: 370px;
				.detailsYear {
					font-size: 30px;
					padding-bottom: 15px;
					border-bottom: 1px solid #d2d2d2;
				}
				.content {
					margin-top: 18px;
					display: flex;
					font-size: 18px;
					div:nth-child(1) {
						color: #333333;
						margin-right: 30px;
					}
					.txt {
						width: 310px;
						color: #5f6464;
						line-height: 30px;
					}
				}
			}
		}
	}
	// .slide::-webkit-scrollbar {
	// 	display: none;
	// }
}
</style>
