<template>
	<div class="userImage">
		<vueSeamless :data="userImage" :class-option="optionCustomer">
			<ul class="userImageAllWrapper">
				<li v-for="item in userImage" :key="item.index">
					<img :src="item" alt="" />
				</li>
			</ul>
		</vueSeamless>
	</div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'
export default {
	name: 'Example3',
	components: {
		vueSeamless,
	},
	data() {
		// 这里存放数据
		return {
			optionCustomer: {
				step: 1,
				limitMoveNum: 4,
				openTouch: false,
				waitTime: 1,
				direction: 2,
				singleWidth: 30,
			},
			userImage: [
				require('@/assets/pc/gaokao/1.png'),
				require('@/assets/pc/gaokao/2.png'),
				require('@/assets/pc/gaokao/3.png'),
				require('@/assets/pc/gaokao/4.png'),
				require('@/assets/pc/gaokao/5.png'),
				require('@/assets/pc/gaokao/6.png'),
				require('@/assets/pc/gaokao/7.png'),
				require('@/assets/pc/gaokao/8.png'),
				require('@/assets/pc/gaokao/9.png'),
				require('@/assets/pc/gaokao/10.png'),
				require('@/assets/pc/gaokao/11.png'),
				require('@/assets/pc/gaokao/12.png'),
			],
		}
	},
}
</script>

<style scoped lang="scss">
.userImage {
	width: 100%;
	margin: 20px auto 32px auto;
	.a {
		display: flex;
	}
	.userImageAllWrapper {
		width: 890px;
		list-style: none;
		display: flex;
		li {
			margin-right: 20px;
			img {
				display: flex;
				width: 203px;
			}
		}
	}
}
</style>
