<template>
	<div class="develop">
		<img src="@/assets/mobile/develop.png" alt="" />
		<div class="whiteTitle">发展历程</div>
		<div class="progress">
			<div class="year">2021</div>
			<div class="year">2020</div>
			<div class="year">2019</div>
			<div class="year">2018</div>
		</div>
		<div class="slide">
			<div class="progressDetails">
				<div
					class="item"
					v-for="(item, i) in progressDetailsList"
					:key="i"
				>
					<div class="detailsYear">{{ item.year }}</div>
					<div
						class="content"
						v-for="(items, index) in item.content"
						:key="index"
					>
						<div>{{ items.month }}</div>
						<div class="txt">{{ items.text }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			progressDetailsList: [
				{
					year: '2021',
					content: [
						{
							month: 'Feb',
							text:
								'APP更新“真题练习”、“趣味学习”等功能，荣获2020年中国教育行业诚信标杆企业。',
						},
						{
							month: 'Oct',
							text: '“德语GO”正式上线',
						},
					],
				},
				{
					year: '2020',
					content: [
						{
							month: 'Jul',
							text:
								'成立数字化学习事业部。“日语GO”APP安卓端上线。学习打卡小程序“打脸吗”上线。',
						},
						{
							month: 'Dec',
							text:
								'APP更新“精品课”、“词汇本”、“每日一练”等功能。日语GO官网上线。',
						},
					],
				},
				{
					year: '2019',
					content: [
						{
							month: 'Dec',
							text:
								'日语班学习人次达到3000+ 。 首届日语班高考日语平均分达106分。荣获2018年度新时代杰出办学单位。',
						},
					],
				},
				{
					year: '2018',
					content: [
						{
							month: 'Jun',
							text:
								'创立初期，为响应普通高中课程改革的总体要求，聚趣教育日语教研团队完成了高考日语整体教学架构。',
						},
						{
							month: 'Aug',
							text: '聚趣教育官网上线。',
						},
						{
							month: 'Dec',
							text:
								'线下日语班学习人次达到1000+。荣获2020年度最佳教育新锐奖。',
						},
					],
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>
.develop {
	position: relative;
	margin-top: 50px;
	overflow: hidden;
	img {
		width: 100%;
	}
	.whiteTitle {
		font-size: 24px;
		color: #fff;
		position: absolute;
		top: 47px;
		left: 16px;
	}
	.progress {
		position: absolute;
		top: 80px;
		display: flex;
		color: #fff;
		// background: rgb(16, 19, 211);
		width: 277px;
		height: 50px;
		left: 16px;
		margin-top: 14px;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 400;
		.year {
			display: flex;
			justify-content: center;
		}
		.year::after {
			content: '';
			position: absolute;
			display: block;
			width: 9px;
			height: 9px;
			top: 17px;
			background-color: #fff;
			z-index: 99;
			border-radius: 50%;
		}
	}
	.progress::after {
		content: '';
		position: absolute;
		display: block;
		width: 246px;
		height: 1px;
		background: #fff;
		top: 21px;
		left: 18px;
	}
	.slide {
		width: 100%;
		padding: 15px;
		box-sizing: border-box;
		.progressDetails {
			display: flex;
			flex-direction: column;
			.item {
				margin-top: 24px;
				margin-right: 30px;
				width: 100%;
				.detailsYear {
					font-size: 24px;
					padding-bottom: 15px;
					border-bottom: 1px solid #d2d2d2;
				}
				.content {
					margin-top: 18px;
					display: flex;
					font-size: 15px;
					div:nth-child(1) {
						color: #333333;
						width: 30px;
						font-weight: bold;
					}
					.txt {
						margin-left: 20px;
						width: 310px;
						color: #5f6464;
						line-height: 30px;
					}
				}
			}
		}
	}
	// .slide::-webkit-scrollbar {
	// 	display: none;
	// }
}
</style>
