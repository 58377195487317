<!--
 * @Author: tjessie
 * @Date: 2022-08-15 14:07:09
 * @LastEditors: tjessie
 * @LastEditTime: 2022-10-18 10:16:50
 * @Description: file content
 * @FilePath: \website\src\views\mobile\mobileContact.vue
-->
<template>
	<div>
		<Bj :bjData="bjData" />
		<div class="contactBox">
			<div
				class="myTitle_box myTitle_mobile_box"
				style="padding-top:28px"
			>
				<div class="myTitle">长沙聚趣教育科技有限公司</div>
				<div
					class="myTitle_behind"
					style="color:;text-stroke: 1px #DDE3EF;-webkit-text-stroke: 1px #DDE3EF;"
				>
					JUQUEDUCATION
				</div>
			</div>
			<div class="company_info_box">
				<div
					class="company_info"
					v-for="(item, i) in companyInfo"
					:key="i"
				>
					<div class="company_info_title">
						{{ item.title }}
					</div>
					<div v-if="i != 2" class="company_info_value">
						<img :src="item.icon" alt="" />
						{{ item.value }}
					</div>
					<div v-else class="company_info_value">
						<img
							style="width:60px;height:60px;"
							mode="widthFix"
							:src="item.value"
							alt=""
						/>
					</div>
				</div>
			</div>
			<img
				class="company_img"
				mode="widthFix"
				src="@/assets/mobile/contact/company_img.png"
				alt=""
			/>
		</div>
		<!-- <myBaiduMap class="myBaiduMap" /> -->
	</div>
</template>

<script>
// import myBaiduMap from '@/components/mobile/baiduMap.vue'
import Bj from '@/components/mobile/bj'
export default {
	components: {
		Bj
		// myBaiduMap
	},
	data() {
		return {
			bjData: {
				imgUrl: 'https://qn.juquedu.com/contactbj_mobile.png',
				title: '联系我们',
				minTitle: '欢迎联络和合作'
			},
			companyInfo: [
				{
					title: '联系方式',
					icon: require('@/assets/pc/contact/icon_2.png'),
					value: '400-862-8806'
				},
				{
					title: '公司地址',
					icon: require('@/assets/pc/contact/icon_3.png'),
					value:
						'湖南省长沙市岳麓区环湖路1177号金茂广场南塔 湘江集团大厦19层'
				},
				{
					title: '商务合作',
					icon: require('@/assets/pc/contact/icon_1.png'),
					value: require('@/assets/pc/pcFooter/code.png')
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.contactBox {
	padding-bottom: 40px;
	background: linear-gradient(360deg, #e3e8f0 0%, #ffffff 100%);
	overflow-x: hidden;
	.company_info_box {
		padding: 4px 20px 4px;
		box-sizing: border-box;
		color: #333333;
		.company_info {
			width: 100%;
			padding: 16px 0;
			border-bottom: 1px solid #efefef;
			display: flex;
			.company_info_title {
				width: 58px;
				font-weight: bold;
				font-size: 14px;
				padding-right: 20px;
			}
			.company_info_value {
				font-weight: 400;
				font-size: 12px;
				display: flex;
				flex: 1;
				padding-top: 3px;
				img {
					width: 14px;
					height: 14px;
					margin-right: 8px;
				}
			}
		}
		.company_info:last-child {
			border: 0;
		}
	}
	.company_img {
		width: 100%;
	}
}
.myBaiduMap {
	margin-bottom: 50px;
}
</style>
