export const navList2 = [{
    id: 1,
    title: '公司介绍',
    name: 'home'
  },
  {
    id: 2,
    title: '公司动态',
    name: 'news'
  },
  {
    id: 4,
    title: '聚趣网校',
    name: 'product'
  },
  {
    id: 5,
    title: '联系我们',
    name: 'contact'
  },
]