<!--
 * @Author: tjessie
 * @Date: 2022-08-15 14:07:09
 * @LastEditors: tjessie
 * @LastEditTime: 2022-10-18 10:16:32
 * @Description: file content
 * @FilePath: \website\src\components\pc\myBaiduMap.vue
-->
<template>
	<div>
		<baidu-map
			class="map"
			:center="{ lng: 112.91067, lat: 28.20199 }"
			:zoom="17"
			:scroll-wheel-zoom="true"
		>
			<bm-marker
				:position="{ lng: 112.91067, lat: 28.20199 }"
				:dragging="true"
				@click="infoWindowOpen"
				:icon="{
					url:
						'https://qn.juquedu.com/bac9f80de81da9fdd60d96a9b0e489e.png',
					size: { width: 100, height: 100 }
				}"
			>
				<bm-info-window
					:show="show"
					@close="infoWindowClose"
					@open="infoWindowOpen"
					class="marker"
				>
					<div>长沙聚趣教育科技有限公司</div>
					<!-- <div>联系方式：guohao@hskgoo.com</div> -->
					<div>联系方式：400-862-8806</div>
					<div>
						地址：湖南省长沙市岳麓区环湖路1177号金茂广场南塔
						湘江集团大厦19层
					</div>
				</bm-info-window>
			</bm-marker>
		</baidu-map>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show: false
		}
	},
	methods: {
		infoWindowClose() {
			this.show = false
		},
		infoWindowOpen() {
			this.show = true
		}
	}
}
</script>

<style lang="scss" scoped>
.map {
	height: 420px;
	width: 1200px;
	margin: auto;
}
.marker {
	div:nth-child(1) {
		color: rgb(238, 93, 10);
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
	}
}
</style>
