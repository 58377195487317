<template>
	<div>
		<Bj :bjData="bjData" />
		<div class="main">
			<div class="myTitle">小语种高考的政策背景</div>
			<div class="miniTitle">语言多样性的重视</div>
			<div class="introduceBox">
				<div
					class="introduce"
					v-for="(item, i) in introduceList"
					:key="i"
				>
					<img :src="item.img" alt="" />
					<div>{{ item.content }}</div>
				</div>
			</div>
			<div class="myTitle">选择小语种参加高考的优势</div>
			<div class="miniTitle">提高就业机会，拓宽视野</div>
			<div class="advantage">
				<img
					v-for="(item, i) in advantageList"
					:key="i"
					:src="item"
					alt=""
				/>
			</div>
			<div class="myTitle">小语种高考常见问题</div>
			<div class="questionBox">
				<div v-for="(item, i) in questionList" :key="i">
					<div class="mediate question">
						<img src="../../assets/pc/gaokao/Q.png" alt="" />
						<span>{{ item.question }}</span>
					</div>
					<div class="answer">
						<img src="../../assets/pc/gaokao/A.png" alt="" />
						<div class=" flexCenter" v-html="item.answer"></div>
					</div>
				</div>
			</div>
			<div class="myTitle">小语种高考VS英语高考</div>
			<img class="form" mode="widthFix" src="../../assets/pc/gaokao/form1.png" alt="" />
			<div class="miniTitle" style="margin:30px 0">高考题型对比</div>
			<img
				class="form" mode="widthFix"
				src="../../assets/pc/gaokao/form2.png"
				style="margin-bottom:40px"
				alt=""
			/>
			<!-- <div class="myTitle">高考日语各地开班情况</div>
			<div class="text-container">
				<transition class="inner-container2" name="slide" mode="out-in">
					<p class="miniTitle" :key="text.id">
						姓名:{{ text.val.name }} &nbsp;&nbsp;日语成绩:{{
							text.val.riYuScore
						}}分 &nbsp;&nbsp; 入班时英语成绩:{{
							text.val.english
						}}分 &nbsp;&nbsp; 提分:{{ text.val.promote }}分
						&nbsp;&nbsp;
						{{ text.val.branch }}
					</p>
				</transition>
			</div> -->
		</div>
		<!-- <Banner /> -->
	</div>
</template>

<script>
import { studentList } from '@/utils/studentList'
import Bj from '@/components/pc/bj'
// import Banner from '@/components/pc/banner'
export default {
	components: {
		Bj
		// Banner,
	},
	data() {
		return {
			times: {},
			arr: [
				{
					name: '沈*',
					riyuScore: '127',
					english: '66',
					promote: '61',
					branch: '文科'
				},
				{
					name: '沈*',
					riyuScore: '127',
					english: '66',
					promote: '61',
					branch: '文科'
				}
			],
			number: 0,
			bjData: {
				imgUrl: 'https://qn.juquedu.com/gaokao_bg.png',
				title: '小语种高考介绍',
				minTitle: '政策背景、高考优势、常见问题、开班情况'
			},
			introduceList: [
				{
					img: require('@/assets/pc/gaokao/bjimg1.png'),
					content:
						'在我国现行的高考制度下，“语数外”中的“外语”科目通常被设定为英语。而实际上高考的“外语”不等同于英语，还可选其他语言参加高考。'
				},
				{
					img: require('@/assets/pc/gaokao/bjimg2.png'),
					content:
						'中国教育部的高考细则中明确规定：除英语外，在高考时还可选择日语、俄语、德语、法语、西班牙语等5门小语种作为外语科目参考。'
				},
				{
					img: require('@/assets/pc/gaokao/bjimg3.png'),
					content:
						'高考时满分均为150 分，均不折扣计入高考总分成绩。同时教育部教提案〔2018〕第190号文件提出，鼓励有条件的学校开设第二外语。'
				},
				{
					img: require('@/assets/pc/gaokao/bjimg4.png'),
					content:
						'而用小语种参加高考优势明显。教育部明确要求高考命题时小语种试卷难度要比英语容易5至10个百分点，更易取得高分。'
				}
			],
			advantageList: [
				require('@/assets/pc/gaokao/advantage1.png'),
				require('@/assets/pc/gaokao/advantage2.png'),
				require('@/assets/pc/gaokao/advantage3.png'),
				require('@/assets/pc/gaokao/advantage4.png')
			],
			questionList: [
				{
					question: '什么样的学生适合用小语种代替英语参加高考？',
					answer: `(1) 英语迟迟没有突破，想利用小语种冲击985/211名校的学生。这类学生英语基础一般，但很难突破到100分以上。通过学习小语种，可以在高
考中外语成绩突破120+分。<br/>
(2) 专科冲本科，二本冲一本的学生，英语成绩长期达不到90分的学生。本身基础不稳固，很难通过3年学习达到理想分数，通过小语种高考，轻松突破100分，提高一个升学档次。<br/>
(3) 文化科目薄弱的艺术以及体育类学生，这类学生水专业优秀，但因为需要学习专业课程精力有限，可以选择小语种以迅速达到提分的目的。<br/>
(4) 对小语种感兴趣，想出国留学的学生，在高中选择小语种学习，为留学抢占先机。`
				},
				{
					question: '怎么上课？会不会占用太多的学习时间，影响其他功课？',
					answer: `(1) 完全不影响学生们的其它科目的课程，只需在英语课（或者自习课）时，选择小语种高考课程的同学到专用教室上课即可。<br/>
 (2) 小语种老师常驻校内，教学并指导学生学习。<br/>
 (3) 同年级选择相同小语种的学生组成一个小语种班班，课程设置与教学和其他班级相同(只是把传统意义上的英语课用其他小语种课替代。`
				},
				{
					question: '高考小语种怎么报考？什么时候报考？',
					answer: `(1) 和其他普通外语类高考生一样报考，不需要任何特殊的报名或者审批。<br/>
(2) 填写高考考试报名报名表时(高三上学期，约十一月上旬左右报考) ，将考试语种选择为学习的小语种即可。`
				},
				{
					question: '对大学选专业有限制吗？',
					answer: `(1) 教育部规定，除军事、国防和公共安全等部分特殊院校(专业) 外，高校不得规定男女生录取比例，不得对报考非外国语言文学类专业的考生作统考外语语种限制，不得在国家招生政策规定外作其他限制。所以只要没有限制条件，考生就都可以报考。<br/>
(2) 填报志愿前，任课教师会一一指导学生， 保证志愿有效性。`
				},
				{
					question: '大学英语四六级和考研的话没有英语成绩怎么办？',
					answer: `事实上，全国大学四六级考试并不只考英语，也考小语种。因此选择小语种参加高考的同学不必担心，大学里依然可以选择小语种四六级考试。`
				}
			]
		}
	},
	computed: {
		text() {
			return {
				id: this.number,
				val: studentList[this.number]
			}
		}
	},
	created() {
		// console.log(studentList)
		this.startMove()
	},
	methods: {
		startMove() {
			this.times = setTimeout(() => {
				if (this.number === studentList.length) {
					this.number = 0
				} else {
					this.number += 1
				}
				this.startMove()
			}, 2000)
		}
	},
	beforeDestroy() {
		clearTimeout(this.times)
	}
}
</script>

<style lang="scss" scoped>
.introduceBox {
	display: flex;
	justify-content: space-between;
	margin-top: 48px;
	.introduce {
		width: 280px;
		font-size: 18px;
		color: #333333;
		line-height: 24px;
		img {
			width: 280px;
			margin-bottom: 20px;
		}
		div {
			// text-align: center;
		}
	}
}
.advantage {
	margin-top: 48px;
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
	justify-content: space-between;
	height: 590px;
	img {
		width: 587px;
	}
}
.questionBox {
	font-size: 18px;
	img {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	.question {
		color: #333333;
		font-weight: 800;
		margin-bottom: 16px;
		margin-top: 30px;
	}
	.answer {
		color: #5f6464;
		display: flex;
		align-content: flex-start;
		padding-bottom: 30px;
		border-bottom: 1px solid #efefef;
	}
}
.form {
	margin-top: 50px;
	width: 1200px;
}

.userImage {
	overflow: hidden;
	.userImageAllWrapper {
		background-color: yellowgreen;
		width: 1160px;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		&:last-child {
			margin-left: -40px;
		}
	}
}
.text-container {
	margin-top: 10px;
	height: 40px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	.slide-enter-active,
	.slide-leave-active {
		transition: all 0.5s linear;
	}
	.slide-leave-to {
		transform: translateY(-30px);
	}
	.slide-enter {
		transform: translateY(20px);
	}
}
</style>
