<!--
 * @Author: tjessie
 * @Date: 2022-08-15 14:07:08
 * @LastEditors: tjessie
 * @LastEditTime: 2024-06-20 11:10:47
 * @Description: file content
 * @FilePath: \website\src\components\mobile\baiduMap.vue
-->
<template>
	<div>
		<baidu-map
			class="map"
			:center="{ lng: 112.91067, lat: 28.20199 }"
			:zoom="17"
			:scroll-wheel-zoom="true"
		>
			<bm-marker
				:position="{ lng: 112.91067, lat: 28.20199 }"
				:dragging="true"
				@click="openBaiduMap"
			></bm-marker>
		</baidu-map>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	methods: {
		openBaiduMap() {
			window.location.href =
				'http://api.map.baidu.com/geocoder?address=湖南省长沙市岳麓区环湖路1177号金茂广场南塔 湘江集团大厦19层&output=html&src=webapp.baidu.openAPIdemo'
		}
	}
}
</script>

<style lang="scss" scoped>
.map {
	height: 251px;
	width: 100%;
	margin: auto;
}
.marker {
	div:nth-child(1) {
		color: rgb(238, 93, 10);
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
	}
}
</style>
