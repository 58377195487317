<!--
 * @Author: tjessie
 * @Date: 2022-02-10 13:36:09
 * @LastEditors: tjessie
 * @LastEditTime: 2022-02-10 13:36:36
 * @Description: file content
 * @FilePath: \juqu-skypegmwcn\src\views\pc\examine.vue
-->
<template>
	<div>
		<div class="content">
			<div class="tab">
				<div
					:class="selectedTab == i ? 'tab_item active' : 'tab_item'"
					v-for="(item, i) in tabList"
					:key="i"
					@click="changeTab(i)"
				>
					{{ item.title }}
				</div>
			</div>
			<div class="data_list">
				<div
					class="data_list_item"
					v-for="(item, i) in dataList4"
					:key="i"
				>
					<div class="data_list_item_left">
						<div class="data_list_item_title">{{ item.title }}</div>
						<div class="data_list_item_price">
							{{ getTypeName(item.salary, 'salary') }}
							<span class="data_list_item_education">
								{{ getTypeName(item.education, 'education') }}
							</span>
						</div>
					</div>
					<div class="data_list_item_right">
						<div class="item_right_item">
							<div style="margin-right:64px;width:245px">
								{{ item.companyName }}
							</div>
							<div>{{ item.tell }}</div>
						</div>
						<div class="item_right_item" style="margin-top:12px">
							<div style="margin-right:64px;width:245px">
								{{ getTypeName(item.issueType, 'issueType') }}
							</div>
							<div>
								{{ getTypeName(item.indate, 'indate') }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="page_list">
				<div
					:class="
						selectedPage == i
							? 'page_list_item active'
							: 'page_list_item'
					"
					v-for="(item, i) in pageList"
					:key="i"
					@click="changePage(i)"
				>
					{{ item }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const axios = require('axios')
export default {
	components: {},
	data() {
		return {
			tabList: [
				{
					title: '线上老师'
				},
				{
					title: '线下老师'
				},
				{
					title: '辅导老师'
				}
			],
			selectedTab: 0,
			dataList: [],
			// dataList1: [
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '一月',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '3000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '一月',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '3000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '4000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '4000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// ],
			// dataList2: [
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '一月',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// ],
			// dataList3: [
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '一月',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线上老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '辅导老师',
			// 		time: '不限',
			// 	},
			// 	{
			// 		title: '信息标题信息标题信息标题信息标题',
			// 		price: '2000-4000',
			// 		education: '本科',
			// 		company: '长沙聚趣教育有限公司',
			// 		phone: '13779795554',
			// 		type: '线下老师',
			// 		time: '不限',
			// 	},
			// ],
			dataList1: [],
			dataList2: [],
			dataList3: [],
			dataList4: [],
			pageList: [],
			selectedPage: 0,
			searchText: '',
			teacherTypeList: [
				{
					id: 0,
					typeName: '线上老师'
				},
				{
					id: 1,
					typeName: '线下老师'
				},
				{
					id: 2,
					typeName: '辅导老师'
				}
			],
			regionTypeList: [
				{
					id: 1,
					typeName: '长沙'
				}
			],
			validityTypeList: [
				{
					id: 0,
					typeName: '长期有效'
				},
				{
					id: 1,
					typeName: '一周'
				},
				{
					id: 2,
					typeName: '一月'
				},
				{
					id: 3,
					typeName: '三月'
				},
				{
					id: 4,
					typeName: '半年'
				}
			],
			salaryTypeList: [
				{
					id: 0,
					typeName: '面议'
				},
				// {
				// 	id: 1,
				// 	typeName: '2000以下',
				// },
				{
					id: 1,
					typeName: '2000-4000'
				},
				{
					id: 2,
					typeName: '4000-8000'
				},
				{
					id: 3,
					typeName: '8000-12000'
				},
				{
					id: 4,
					typeName: '12000以上'
				}
			],
			educationTypeList: [
				{
					id: 0,
					typeName: '不限'
				},
				{
					id: 1,
					typeName: '初中及以下'
				},
				{
					id: 2,
					typeName: '高中、中专'
				},
				{
					id: 3,
					typeName: '大专'
				},
				{
					id: 4,
					typeName: '本科'
				},
				{
					id: 5,
					typeName: '硕士'
				},
				{
					id: 6,
					typeName: '博士及以上'
				}
			]
		}
	},
	created: function() {
		this.insertIssue(this.$route.query.title, this.$route.query.searchText)
	},
	methods: {
		// 查询发布信息
		async insertIssue(title, searchText) {
			var params = new URLSearchParams()
			params.append('keyWord', searchText)
			axios
				.post(
					'https://api.juquedu.com/admin/apply/selectIssueAll',
					params
				)
				.then((result) => {
					if (result.status == 1001) {
						this.dataList1 = result.data[0].list
						this.dataList2 = result.data[1].list
						this.dataList3 = result.data[2].list
						switch (title) {
							case '线上老师':
								this.selectedTab = 0
								this.dataList = this.dataList1
								break
							case '线下老师':
								this.selectedTab = 1
								this.dataList = this.dataList2
								break
							case '辅导老师':
								this.selectedTab = 2
								this.dataList = this.dataList3
								break
						}
						if (this.$route.query.searchText) {
							this.searchText = this.$route.query.searchText
						}
						var num = Math.ceil(this.dataList.length / 10)
						for (var i = 1; i <= num; i++) {
							this.pageList.push(i)
						}
						this.dataList4 = this.dataList.slice(0, 10)
					}
				})
		},
		changeTab(value) {
			this.selectedTab = value
			this.selectedPage = 0
			switch (value) {
				case 0:
					this.dataList = this.dataList1
					this.dataList4 = this.dataList1.slice(0, 10)
					break
				case 1:
					this.dataList = this.dataList2
					this.dataList4 = this.dataList2.slice(0, 10)
					break
				case 2:
					this.dataList = this.dataList3
					this.dataList4 = this.dataList3.slice(0, 10)
					break
			}
			this.pageList = []
			var num = Math.ceil(this.dataList.length / 10)
			for (var i = 1; i <= num; i++) {
				this.pageList.push(i)
			}
		},
		changePage(value) {
			this.selectedPage = value
			var page_num = parseInt(this.selectedPage + '0')
			this.dataList4 = this.dataList.slice(
				page_num,
				10 * (this.selectedPage + 1)
			)
		},
		getTypeName(id, type) {
			var options = []
			switch (type) {
				case 'salary':
					options = this.salaryTypeList
					break
				case 'education':
					options = this.educationTypeList
					break
				case 'issueType':
					options = this.teacherTypeList
					break
				case 'indate':
					options = this.validityTypeList
					break
			}
			// if (!id) {
			// 	return null
			// }
			// data为集合
			for (let i = 0, len = options.length; i < len; i++) {
				let item = options[i]
				if (item.id == id) {
					console.log(item.typeName)
					return item.typeName
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	width: 1200px;
	margin: 0 auto;
	.tab {
		width: 1200px;
		height: 124px;
		display: flex;
		align-items: center;
		.tab_item {
			width: 200px;
			height: 56px;
			background: #ffd5d5;
			border-radius: 27px;
			font-size: 20px;
			color: #333333;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 32px;
		}
		.tab_item:last-child {
			margin-right: 0;
		}
		.active {
			background: #ff293e;
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
			color: #ffffff;
		}
	}
	.data_list {
		.data_list_item {
			height: 94px;
			border-bottom: 1px solid #ececec;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.data_list_item_left {
				display: flex;
				flex-direction: column;
				font-size: 20px;
				color: #333333;
				.data_list_item_title {
					font-weight: bold;
				}
				.data_list_item_price {
					font-size: 16px;
					color: #ff293e;
					padding-top: 18px;
					.data_list_item_education {
						padding-left: 24px;
						color: #3459ec;
					}
				}
			}
			.data_list_item_right {
				font-size: 20px;
				color: #333333;
				.item_right_item {
					display: flex;
					align-items: center;
				}
			}
		}
		.data_list_item:last-child {
			border: 0;
		}
		.data_list_item:nth-child(10) {
			border: 0;
		}
		.data_list_item:nth-child(20) {
			border: 0;
		}
		.data_list_item:nth-child(30) {
			border: 0;
		}
		.data_list_item:nth-child(40) {
			border: 0;
		}
	}
	.page_list {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 150px 0 50px 0;
		.page_list_item {
			width: 54px;
			height: 54px;
			background: #f1f1f1;
			border-radius: 6px;
			font-size: 20px;
			font-weight: bold;
			color: #333333;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;
		}
		.page_list_item:last-child {
			margin-right: 0;
		}
		.active {
			background: #ff293e;
			color: #ffffff;
		}
	}
}
</style>
