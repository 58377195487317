<template>
	<div class="banner">
		<img @click="clickLeft" src="@/assets/pc/gaokao/left.png" alt="" />
		<div class="bannerBox">
			<img
				class="bannerImg"
				v-for="(item, index) in imgArray"
				:key="index"
				:src="item"
			/>
		</div>
		<img @click="clickRight" src="@/assets/pc/gaokao/right.png" alt="" />
	</div>
</template>

<script>
export default {
	name: 'banner',
	data() {
		return {
			n: 0, //默认图片是第一张开始
			imgArray: [
				require('@/assets/pc/gaokao/1.png'),
				require('@/assets/pc/gaokao/2.png'),
				require('@/assets/pc/gaokao/3.png'),
				require('@/assets/pc/gaokao/4.png'),
			], //获取图片位置
		}
	},
	created() {
		this.play()
	},
	methods: {
		clickLeft() {
			let direction = 'left'
			this.scrollImg(direction)
		},
		clickRight() {
			let direction = 'right'
			this.scrollImg(direction)
		},
		scrollImg(direction) {
			if (direction === 'left') {
				let first = this.imgArray.shift()
				this.imgArray.push(first)
			} else {
				let last = this.imgArray.pop()
				this.imgArray.unshift(last)
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.timer) //清除定时器
	},
}
</script>

<style lang="scss" scoped>
.banner {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 48px 0 100px 0;
	.bannerBox {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: 0 20px;
		min-width: 1200px;
		overflow: hidden;
	}
	.bannerImg {
		width: 280px;
		margin-right: 20px;
	}
	.icon {
		width: 32px;
	}
}
</style>
