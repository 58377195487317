<!--
 * @Author: tjessie
 * @Date: 2022-08-15 14:07:09
 * @LastEditors: tjessie
 * @LastEditTime: 2022-10-18 10:16:15
 * @Description: file content
 * @FilePath: \website\src\views\pc\contact.vue
-->
<template>
	<div class="content">
		<Bj :bjData="bjData" />
		<div class="contactBox">
			<div class="myTitle_box" style="padding-top:100px">
				<div class="myTitle">长沙聚趣教育科技有限公司</div>
				<div
					class="myTitle_behind"
					style="color:;text-stroke: 1px #DDE3EF;-webkit-text-stroke: 1px #DDE3EF;"
				>
					JUQUEDUCATION
				</div>
			</div>
			<img
				class="company_icon"
				src="@/assets/pc/contact/left.png"
				alt=""
				mode="widthFix"
			/>
			<img
				class="company_icon"
				src="@/assets/pc/contact/right.png"
				alt=""
				mode="widthFix"
			/>
			<div class="company_box">
				<div class="company_info_box">
					<div
						class="company_info"
						v-for="(item, i) in companyInfo"
						:key="i"
					>
						<div class="company_info_title">{{ item.title }}</div>
						<div v-if="i != 2" class="company_info_value">
							<img :src="item.icon" alt="" />
							{{ item.value }}
						</div>
						<div v-else class="company_info_value">
							<img
								style="width:100px;height:100px;"
								mode="widthFix"
								:src="item.value"
								alt=""
							/>
						</div>
					</div>
				</div>
				<img
					class="company_img"
					src="@/assets/pc/contact/company_img.png"
					alt=""
				/>
			</div>
		</div>
		<!-- <myBaiduMap class="myBaiduMap" /> -->
	</div>
</template>

<script>
// import myBaiduMap from '@/components/pc/myBaiduMap.vue'
import Bj from '@/components/pc/bj'
export default {
	components: {
		Bj
		// myBaiduMap
	},
	data() {
		return {
			bjData: {
				imgUrl: 'https://qn.juquedu.com/contactbj-20240627175152.png',
				title: '联系我们',
				minTitle: '欢迎联络和合作'
			},
			companyInfo: [
				{
					title: '联系方式',
					icon: require('@/assets/pc/contact/icon_2.png'),
					value: '400-862-8806'
				},
				{
					title: '公司地址',
					icon: require('@/assets/pc/contact/icon_3.png'),
					value:
						'湖南省长沙市岳麓区环湖路1177号金茂广场南塔 湘江集团大厦19层'
				},
				{
					title: '商务合作',
					icon: require('@/assets/pc/contact/icon_1.png'),
					value: require('@/assets/pc/pcFooter/code.png')
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	width: 100%;
	background: linear-gradient(270deg, #dde1e8 0%, #edf2f5 100%);
	.contactBox {
		width: 100%;
		margin: 0 auto;
		padding-bottom: 80px;
		box-sizing: border-box;
		position: relative;
		.company_box {
			width: 1461px;
			margin: 0 auto;
			height: 641px;
			padding: 30px 0 0 130px;
			box-sizing: border-box;
			display: flex;
			position: relative;
			z-index: 2;
			.company_info_box {
				color: #333333;
				z-index: 2;
				.company_info {
					width: 251px;
					padding-top: 48px;
					.company_info_title {
						font-weight: bold;
						font-size: 24px;
						padding-bottom: 20px;
						border-bottom: 1px solid #cecece;
					}
					.company_info_value {
						padding-top: 30px;
						font-weight: 400;
						font-size: 18px;
						display: flex;
						img {
							width: 23px;
							height: 23px;
							margin-right: 20px;
						}
					}
				}
			}
			.company_img {
				width: 1299px;
				height: 641px;
				position: absolute;
				top: 30px;
				right: 0;
				z-index: 1;
			}
		}
		.company_icon {
			position: absolute;
			z-index: 1;
		}
		.company_icon:nth-child(2) {
			width: 108px;
			height: 325px;
			bottom: 80px;
			left: 0;
		}
		.company_icon:nth-child(3) {
			width: 118px;
			height: 436px;
			top: 50px;
			right: 0;
		}
	}
}
</style>
