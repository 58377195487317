<template>
	<div>
		<Bj :bjData="bjData" />
		<div class="asTxt">
			<div class="tit">| 关于聚趣 |</div>
			<div class="txt">
				聚趣是一个以智慧教育为主体，以课程辅导为载体，在全球范围内服务公办教育，助力民办教育，
			</div>
			<div class="txt">
				探索未来教育新模式的科技教育公司。
			</div>
		</div>
		<div class="bodyBox">
			<div class="myTitle bgfb">科技助教育乘风破浪</div>
			<div class="bgfb">
				<div class="phone">
					<img src="../../assets/pc/home/phone.png" alt="" />
					<div>
						<div class="phoneTxt">
							<div>智能学习系统</div>
							<div>
								优化出题，根据用户学习习惯及答题选择生成相匹配题型
							</div>
						</div>
						<div class="phoneTxt mg_top54">
							<div>科学算法针对性推送</div>
							<div>
								对于用户使用过程中习惯进行分析，对用户推荐最适合的学习内容
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="myTitle">为什么选择聚趣</div>
			<div class="miniTitle">专业团队精心打造，全方位助力学习</div>
			<div class="detailsBox">
				<div class="navList">
					<div
						class="item"
						@click="select(item, i)"
						v-for="(item, i) in navList"
						:key="i"
						:style="{
							'font-weight': i == navIndex ? 'bold' : '',
						}"
					>
						{{ item.title }}
						<div :class="i == navIndex ? 'index' : ''"></div>
					</div>
				</div>
				<img
					class="detailsImg"
					:src="require('@/' + detailsImg)"
					alt=""
				/>
				<!-- <button class="btn" @click="goToWebsite">查看详情</button> -->
			</div>
			<!-- <div class="myTitle">日语高考学习方案</div>
			<div class="miniTitle">优秀的学习方案，让学习事半功倍</div>
			<MoveSquare />
			<button class="btn" @click="$router.push('/gaokao')">
				更多日语高考相关
			</button> -->
			<div class="myTitle bgfb">更多学员青睐的专业学习方案</div>
			<div class="miniTitle bgfb">
				2020年86117个学员的选择，超过3000名优质教师，为您提供专业的学习方案
			</div>
			<div class="learning_program_out bgfb">
				<div class="learning_program">
					<div
						class="learning_program_item"
						v-for="(item, i) in learningList"
						:key="i"
					>
						<img
							class="learning_program_img"
							:src="require('@/' + item.imgUrl)"
							alt=""
						/>
						<div class="learning_program_title">
							{{ item.title }}
						</div>
						<div class="learning_program_des">{{ item.des }}</div>
						<div class="learning_program_introduce">
							{{ item.introduce }}
						</div>
					</div>
				</div>
			</div>
			<Develop />
		</div>
	</div>
</template>

<script>
// import MoveSquare from '@/components/pc/moveSquare.vue'
import Develop from '@/components/pc/develop.vue'
import Bj from '@/components/pc/bj'
export default {
	components: {
		Bj,
		// MoveSquare,
		Develop,
	},
	data() {
		return {
			bjData: {
				imgUrl: 'https://qn.juquedu.com/home.png',
				title: '选择聚趣',
				minTitle: '让学习更有趣',
			},
			navIndex: 0,
			navList: [
				{
					title: '用户第一',
					imgUrl: 'assets/pc/home/introduceOne.png',
				},
				{
					title: '自主研发',
					imgUrl: 'assets/pc/home/introduceTwo.png',
				},
				{
					title: '服务流程',
					imgUrl: 'assets/pc/home/introduceThree.png',
				},
				{
					title: '教学质量把控',
					imgUrl: 'assets/pc/home/introduceFour.png',
				},
				{
					title: '品控把控',
					imgUrl: 'assets/pc/home/introduceFive.png',
				},
				{
					title: '教研工作',
					imgUrl: 'assets/pc/home/introduceSix.png',
				},
			],
			detailsImg: 'assets/pc/home/introduceOne.png',
			learningList: [
				{
					imgUrl: 'assets/pc/home/learning1.png',
					title: '高达标率',
					des: '人均提分42.68分',
					introduce:
						'2020年有79228名学员达标，参课人数超10w+,推荐率91%。',
				},
				{
					imgUrl: 'assets/pc/home/learning2.png',
					title: '学生现状',
					des: '人均提分42.68分',
					introduce:
						'APP的语音测评次数突破5000万次，日均测试量为11万次',
				},
				{
					imgUrl: 'assets/pc/home/learning3.png',
					title: '直播授课',
					des: '前所未有的强互动',
					introduce:
						'告别懒散低效的大班，以100%的注意力与您互动练习，确保您有超过 一半的开口时间',
				},
				{
					imgUrl: 'assets/pc/home/learning4.png',
					title: '多导师辅导',
					des: '4位老师多方位辅导',
					introduce:
						'告别懒散低效的大班，以100%的注意力与您互动练习，确保您有超过 一半的开口时间',
				},
			],
		}
	},
	methods: {
		select(item, i) {
			this.navIndex = i
			this.detailsImg = item.imgUrl
		},
		goToWebsite() {
			window.open('https://api.juquedu.com/website/index.html')
		},
	},
}
</script>

<style lang="scss" scoped>
.asTxt {
	height: 240px;
	width: 100%;
	min-width: 1200px;
	background-color: #ff293e;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.tit {
		font-size: 38px;
		margin-bottom: 20px;
	}
	.txt {
		margin-top: 10px;
	}
}
.bgfb {
	background: #fbfbfb;
}
.bodyBox {
	width: 100%;
	min-width: 1200px;
	margin: 0 auto;
	.btn {
		display: block;
		margin: 54px auto 0 auto;
		width: 188px;
		height: 68px;
		background: #ff293e;
		box-shadow: 0px 4px 10px rgba(255, 77, 94, 0.3);
		border-radius: 8px;
		color: #ffffff;
		font-size: 20px;
	}
	phone bgfb .btn:hover {
		cursor: pointer;
	}
	.btn:active {
		background: #750c16;
	}
	.detailsBox {
		height: 727px;
		.navList {
			display: flex;
			justify-content: space-between;
			width: 1200px;
			height: 53px;
			border-bottom: 1px solid #dde1e5;
			font-size: 14px;
			margin: 48px auto 0 auto;
			font-size: 24px;
			.item {
				cursor: pointer;
				.index {
					width: 32px;
					margin: auto;
					content: '';
					position: relative;
					top: 16px;
					display: block;
					height: 4px;
					text-align: center;
					background-color: #ff293e;
					border-radius: 2px;
					animation: fadeInLeft 0.3s ease 0.2s 1 both;
				}
			}
		}
		.detailsImg {
			display: block;
			margin: 27px auto 0 auto;
			width: 1200px;
		}
	}
	.learning_program_out {
		height: 469px;
		.learning_program {
			width: 1200px;
			margin: 0 auto;
			height: 469px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.learning_program_item {
				width: 182px;
				height: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				.learning_program_img {
					width: 108px;
					height: 108px;
					margin-top: 64px;
				}
				.learning_program_title {
					margin-top: 18px;
					font-size: 24px;
					font-weight: 800;
					color: #333333;
				}
				.learning_program_des {
					margin-top: 6px;
					font-size: 14px;
					color: #949494;
				}
				.learning_program_introduce {
					margin-top: 32px;
					font-size: 16px;
					color: #333333;
				}
			}
		}
	}
	.phone {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 638px;
		width: 1200px;
		margin: 0 auto;
		img {
			width: 517px;
		}
		.mg_top54 {
			margin-top: 54px;
		}
		.phoneTxt {
			letter-spacing: 2.5px;
			div:nth-child(1) {
				font-size: 28px;
				color: #333333;
				font-weight: bold;
				display: flex;
				align-items: center;
			}
			div:nth-child(1)::before {
				content: '';
				display: inline-block;
				width: 3px;
				height: 30px;
				background-color: #ff3838;
				margin-right: 16px;
			}
			div:nth-child(2) {
				margin-top: 14px;
				color: #5f6464;
			}
		}
	}
}
</style>
