<template>
	<div class="learningPlan">
		<div
			class="rectangle"
			:style="{
				height: i == 1 ? 162 + 'px' : '' || i == 2 ? 162 + 'px' : '',
				background: item.color,
			}"
			v-for="(item, i) in learningPlanList"
			:key="i"
		>
			<div class="name">{{ item.name }}</div>
			<div
				class="content"
				v-for="(items, index) in item.content"
				:key="index"
			>
				<div
					class="trapezoidBox"
					:style="{
						background: items.color,
						width: items.width,
					}"
					@touchstart="enter(i, index, items.width)"
					@touchend="leave(i, index, items.width)"
				>
					<div class="title">{{ items.title }}</div>
				</div>
				<img :src="items.trapezoidImg" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			learningPlanList: [
				{
					imgVisible: 0,
					name: '0基础',
					color: '#F1F9FF',
					content: [
						{
							width: '150px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid1.png'),
							color: '#83BFF3',
						},
					],
				},
				{
					imgVisible: 0,
					name: '基础知识',
					color: '#FFF6EE',
					content: [
						{
							width: '165px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid2.png'),
							color: '#FFB350',
							title: '五十音、七年级、八年级、九年级',
						},
						{
							width: '180px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid3.png'),
							color: '#FFA347',
							title: '必修一、必修二、必修三、必修四、必修五',
						},
					],
				},
				{
					imgVisible: 0,
					name: '专项复习',
					color: '#FFEFE4',
					content: [
						{
							width: '195px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid4.png'),
							color: '#FF8E43',
							title: '听力冲刺',
						},
						{
							width: '210px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid5.png'),
							color: '#FF7630',
							title: '基础、进阶知识点、语法基础、语法进阶训练',
						},
					],
				},
				{
					imgVisible: 0,
					name: '考前冲刺',
					color: '#FFEAD5',
					content: [
						{
							width: '225px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid6.png'),
							color: '#FF5E21',
							title: '听力、语法、作文、阅读冲刺',
						},
					],
				},
				{
					imgVisible: 0,
					name: '高考',
					color: '#DBFFF1',
					content: [
						{
							width: '240px',
							trapezoidImg: require('@/assets/mobile/home/trapezoid7.png'),
							color: '#58D9A5',
						},
					],
				},
			],
		}
	},
	methods: {
		enter(i, index, width) {
			let w = width.substr(0, width.length - 2) * 1
			this.learningPlanList[i].content[index].width = w + 20 + 'px'
			this.learningPlanList[i].imgVisible = 1
		},
		leave(i, index, width) {
			let w = width.substr(0, width.length - 2) * 1
			this.learningPlanList[i].content[index].width = w - 20 + 'px'
			this.learningPlanList[i].imgVisible = 0
		},
	},
}
</script>

<style lang="scss" scoped>
.learningPlan {
	margin-top: 48px;
	height: 589px;
	display: flex;
	// background-color: blueviolet;
	flex-direction: column;
	.rectangle {
		position: relative;
		width: 100%;
		margin-top: 6px;
		height: 78px;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		.name {
			position: absolute;
			font-size: 14px;
			color: #333333;
			font-weight: bold;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}
		.selectedBj {
			position: absolute;
			width: 100%;
		}
		.miniTitle {
			width: 100%;
			text-align: center;
			font-size: 14px;
			position: absolute;
			top: 65px;
			display: flex;
			flex-direction: column;
			color: #5f6464;
			align-items: center;
			div {
				margin-bottom: 10px;
			}
		}
		.content {
			position: relative;
			height: 78px;
			display: flex;
			align-items: center;
			overflow: hidden;
			img {
				height: 78px;
			}
			.trapezoidBox {
				height: 78px;
				transition: width 0.5s;
				display: flex;
				align-items: center;
				border-radius: 5px 0 0 5px;
			}
			.title {
				margin-left: 15px;
				margin-right: 10px;
				height: 50px;
				font-size: 14px;
				width: 140px;
				color: #fff;
			}
		}
		.content:hover::before {
			content: '';
			display: block;
			width: 18px;
			height: 18px;
			background-color: #fff;
			position: absolute;
			left: -12px;
			transform: rotate(45deg);
		}
		.trapezoid {
			bottom: 0;
			width: 165px;
			position: absolute;
			height: 145px;
			background: rgb(182, 38, 38);
		}
	}
}
</style>
