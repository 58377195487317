import { render, staticRenderFns } from "./moveSquare.vue?vue&type=template&id=b7763b44&scoped=true&"
import script from "./moveSquare.vue?vue&type=script&lang=js&"
export * from "./moveSquare.vue?vue&type=script&lang=js&"
import style0 from "./moveSquare.vue?vue&type=style&index=0&id=b7763b44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7763b44",
  null
  
)

export default component.exports