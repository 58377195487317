export const studentList = [
	{
		name: '沈*',
		riYuScore: '127',
		english: '66',
		promote: '61',
		branch: '文科',
	},
	{
		name: '张*尧',
		riYuScore: '119',
		english: '57',
		promote: '62',
		branch: '文科',
	},
	{
		name: '梁*曼',
		riYuScore: '99',
		english: '43',
		promote: '56',
		branch: '理科（体）',
	},
	{
		name: '陆*琳',
		riYuScore: '124',
		english: '59',
		promote: '65',
		branch: '文科',
	},
	{
		name: '张*嘉',
		riYuScore: '123',
		english: '65',
		promote: '58',
		branch: '理科',
	},
	{
		name: '付*宸',
		riYuScore: '105',
		english: '38',
		promote: '67',
		branch: '理科',
	},
	{
		name: '胡*丰',
		riYuScore: '89',
		english: '37',
		promote: '52',
		branch: '文科（艺）',
	},
	{
		name: '廖*彬',
		riYuScore: '100',
		english: '50',
		promote: '50',
		branch: '文科',
	},
	{
		name: '韦*乐',
		riYuScore: '91',
		english: '29',
		promote: '62',
		branch: '理科',
	},
	{
		name: '郑*希',
		riYuScore: '112',
		english: '42',
		promote: '70',
		branch: '理科',
	},
	{
		name: '张*昊',
		riYuScore: '107',
		english: '36',
		promote: '71',
		branch: '理科',
	},
	{
		name: '张*民',
		riYuScore: '108',
		english: '45',
		promote: '63',
		branch: '文科',
	},
	{
		name: '宋*明',
		riYuScore: '126',
		english: '63',
		promote: '63',
		branch: '文科',
	},
	{
		name: '田*之',
		riYuScore: '126',
		english: '67',
		promote: '59',
		branch: '理科',
	},
	{
		name: '杨*曦',
		riYuScore: '107',
		english: '41',
		promote: '66',
		branch: '理科',
	},
	{
		name: '彭*华',
		riYuScore: '92',
		english: '42',
		promote: '50',
		branch: '理科',
	},
	{
		name: '宋*玉',
		riYuScore: '86',
		english: '35',
		promote: '51',
		branch: '理科（体）',
	},
	{
		name: '包*浩',
		riYuScore: '92',
		english: '39',
		promote: '53',
		branch: '理科',
	},
	{
		name: '黄*禹',
		riYuScore: '117',
		english: '57',
		promote: '60',
		branch: '文科',
	},
	{
		name: '任*悦',
		riYuScore: '114',
		english: '54',
		promote: '60',
		branch: '文科',
	},
	{
		name: '唐*',
		riYuScore: '89',
		english: '29',
		promote: '60',
		branch: '理科',
	},
	{
		name: '黄*强',
		riYuScore: '89',
		english: '33',
		promote: '56',
		branch: '理科',
	},
	{
		name: '丁*珺',
		riYuScore: '107',
		english: '43',
		promote: '64',
		branch: '理科',
	},
	{
		name: '张*轩',
		riYuScore: '89',
		english: '45',
		promote: '44',
		branch: '文科（艺）',
	},
	{
		name: '曾*杰',
		riYuScore: '99',
		english: '34',
		promote: '65',
		branch: '文科',
	},
	{
		name: '夏*',
		riYuScore: '118',
		english: '42',
		promote: '76',
		branch: '理科',
	},
	{
		name: '商*曦',
		riYuScore: '114',
		english: '43',
		promote: '71',
		branch: '理科',
	},
	{
		name: '李*杰',
		riYuScore: '117',
		english: '51',
		promote: '66',
		branch: '理科',
	},
	{
		name: '谢*宣',
		riYuScore: '79',
		english: '34',
		promote: '45',
		branch: '理科',
	},
	{
		name: '赵*泽',
		riYuScore: '104',
		english: '37',
		promote: '67',
		branch: '理科',
	},
	{
		name: '张*南',
		riYuScore: '116',
		english: '39',
		promote: '77',
		branch: '文科',
	},
	{
		name: '任*',
		riYuScore: '90',
		english: '43',
		promote: '47',
		branch: '文科',
	},
	{
		name: '李*坤',
		riYuScore: '88',
		english: '49',
		promote: '39',
		branch: '理科（艺）',
	},
	{
		name: '张*萌',
		riYuScore: '119',
		english: '52',
		promote: '67',
		branch: '文科',
	},
	{
		name: '王*川',
		riYuScore: '98',
		english: '47',
		promote: '51',
		branch: '文科',
	},
	{
		name: '陈*硕',
		riYuScore: '96',
		english: '39',
		promote: '57',
		branch: '理科（艺）',
	},
	{
		name: '张*龙',
		riYuScore: '126',
		english: '48',
		promote: '78',
		branch: '理科',
	},
	{
		name: '陈*钧',
		riYuScore: '124',
		english: '56',
		promote: '68',
		branch: '理科',
	},
	{
		name: '王*浩',
		riYuScore: '112',
		english: '39',
		promote: '73',
		branch: '理科',
	},
	{
		name: '郭*远',
		riYuScore: '117',
		english: '38',
		promote: '79',
		branch: '文科',
	},
	{
		name: '李*彤',
		riYuScore: '107',
		english: '42',
		promote: '65',
		branch: '文科',
	},
	{
		name: '袁*雨',
		riYuScore: '104',
		english: '37',
		promote: '67',
		branch: '文科',
	},
	{
		name: '张*栋',
		riYuScore: '91',
		english: '40',
		promote: '51',
		branch: '理科（体）',
	},
]
