<template>
	<div class="banner">
		<el-carousel
			:interval="3000"
			arrow="never"
			:autoplay="true"
			:height="imgHeight"
		>
			<el-carousel-item v-for="(item, i) in carouselData" :key="i">
				<img
					ref="imgHeight"
					class="banner_img"
					mode="widthFix"
					:src="item"
					@load="imgLoad"
					alt=""
				/>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
export default {
	name: 'carousel',
	props: {
		carouselData: Array
	},
	data() {
		return {
			imgHeight: ''
		}
	},
	mounted() {
		this.imgLoad()
		window.onresize = () => {
			this.imgLoad()
		}
	},
	created() {},
	methods: {
		imgLoad() {
			// 获取图片高度
			this.$nextTick(() => {
				if (this.$refs.imgHeight.length > 0) {
					this.imgHeight = this.$refs.imgHeight['0'].height + 'px'
				}
			})
		}
	},
	destroyed() {
		window.onresize = null
	}
}
</script>

<style lang="scss" scoped>
.banner {
	width: 100%;
	.banner_img {
		width: 100%;
	}
	/deep/ .el-carousel--horizontal {
		height: 100%;
	}
	/deep/ .el-carousel__indicator--horizontal {
		display: inline-block;
		padding: 12px 4px;
	}
	/deep/ .el-carousel__button {
		display: block;
		width: 12px;
		height: 12px;
		background: #d9d9d9;
		border-radius: 50%;
		opacity: 0.7;
		border: 0 !important;
		border-radius: 50%;
		border: none;
		outline: 0;
		padding: 0;
		margin: 0;
		cursor: pointer;
		transition: 0.3s;
	}
	/deep/ .el-carousel__indicator.is-active button {
		width: 24px;
		height: 12px;
		background: #ffffff;
		opacity: 1;
		border-radius: 12px;
	}
}
</style>
