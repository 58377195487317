<template>
	<div class="learningPlan main">
		<div
			class="rectangle"
			:style="{
				width: i == 1 ? 338 + 'px' : '' || i == 2 ? 338 + 'px' : '',
				background: item.color,
			}"
			v-for="(item, i) in learningPlanList"
			:key="i"
		>
			<div class="name">{{ item.name }}</div>
			<img
				v-if="item.imgVisible"
				class="selectedBj"
				:src="item.selectedBj"
				alt=""
			/>
			<div class="miniTitle" v-if="item.imgVisible">
				<div
					v-for="(miniTitleItem, i) in item.miniTitle"
					:key="i"
					v-html="miniTitleItem"
				></div>
			</div>
			<div
				class="content"
				v-for="(items, index) in item.content"
				:key="index"
			>
				<img :src="items.trapezoidImg" alt="" />
				<div
					class="trapezoidBox"
					:style="{
						background: items.color,
						height: items.height,
					}"
					@mouseenter="enter(i, index, items.height)"
					@mouseleave="leave(i, index, items.height)"
				>
					<div class="title" v-for="(hh, i) in items.title" :key="i">
						{{ hh }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			learningPlanList: [
				{
					imgVisible: 0,
					name: '0基础',
					color: '#F1F9FF',
					selectedBj: require('@/assets/pc/home/contentBj1.png'),
					content: [
						{
							height: '113px',
							trapezoidImg: require('@/assets/pc/home/trapezoid1.png'),
							color: '#83BFF3',
						},
					],
				},
				{
					imgVisible: 0,
					name: '基础知识',
					color: '#FFF6EE',
					selectedBj: require('@/assets/pc/home/contentBj2.png'),
					miniTitle: ['一课一练阶段习题<br/>阶段模考(含解析)'],
					content: [
						{
							height: '136px',
							trapezoidImg: require('@/assets/pc/home/trapezoid2.png'),
							color: '#FFB350',
							title: ['五十音', '七年级', '八年级', '九年级'],
						},
						{
							height: '159px',
							trapezoidImg: require('@/assets/pc/home/trapezoid3.png'),
							color: '#FFA347',
							title: [
								'必修一',
								'必修二',
								'必修三',
								'必修四',
								'必修五',
							],
						},
					],
				},
				{
					imgVisible: 0,
					name: '专项复习',
					color: '#FFEFE4',
					miniTitle: [
						'一课一练阶段习题<br/>阶段模考(含解析)',
						'模拟真题练习<br/>（考前刷题）',
					],

					selectedBj: require('@/assets/pc/home/contentBj3.png'),
					content: [
						{
							height: '181px',
							trapezoidImg: require('@/assets/pc/home/trapezoid4.png'),
							color: '#FF8E43',
							title: ['听力冲刺'],
						},
						{
							height: '203px',
							trapezoidImg: require('@/assets/pc/home/trapezoid5.png'),
							color: '#FF7630',
							title: [
								'基础知识点',
								'进阶知识点',
								'语法基础训练',
								'语法进阶训练',
							],
						},
					],
				},
				{
					imgVisible: 0,
					name: '考前冲刺',
					color: '#FFEAD5',
					selectedBj: require('@/assets/pc/home/contentBj4.png'),
					miniTitle: ['模拟真题练习<br/>（考前刷题）'],
					content: [
						{
							height: '225px',
							trapezoidImg: require('@/assets/pc/home/trapezoid6.png'),
							color: '#FF5E21',
							title: [
								'听力冲刺',
								'语法冲刺',
								'作文冲刺',
								'阅读冲刺',
							],
						},
					],
				},
				{
					imgVisible: 0,
					name: '高考',
					color: '#DBFFF1',
					selectedBj: require('@/assets/pc/home/contentBj5.png'),
					content: [
						{
							height: '246px',
							trapezoidImg: require('@/assets/pc/home/trapezoid7.png'),
							color: '#58D9A5',
						},
					],
				},
			],
		}
	},
	methods: {
		enter(i, index, height) {
			let h = height.substr(0, height.length - 2) * 1
			this.learningPlanList[i].content[index].height = h + 50 + 'px'
			this.learningPlanList[i].imgVisible = 1
		},
		leave(i, index, height) {
			let h = height.substr(0, height.length - 2) * 1
			this.learningPlanList[i].content[index].height = h - 50 + 'px'
			this.learningPlanList[i].imgVisible = 0
		},
	},
}
</script>

<style lang="scss" scoped>
.learningPlan {
	margin-top: 48px;
	height: 480px;
	display: flex;
	justify-content: space-between;
	.rectangle {
		position: relative;
		width: 165px;
		height: 480px;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		overflow: hidden;
		.name {
			width: 100%;
			text-align: center;
			position: absolute;
			top: 20px;
			font-size: 24px;
			color: #333333;
			font-weight: bold;
		}
		.selectedBj {
			position: absolute;
			width: 100%;
		}
		.miniTitle {
			width: 100%;
			text-align: center;
			font-size: 14px;
			position: absolute;
			top: 65px;
			display: flex;
			flex-direction: column;
			color: #5f6464;
			align-items: center;
			div {
				margin-bottom: 10px;
			}
		}
		.content {
			position: relative;
			width: 165px;
			img {
				display: block;
			}
			.trapezoidBox {
				border-radius: 0 0 8px 8px;
				transition: height 0.5s;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}
			.title {
				font-size: 20px;
				color: #fff;
				text-align: center;
				line-height: 28px;
			}
		}
		.content:hover::after {
			content: '';
			display: block;
			width: 30px;
			height: 30px;
			border-radius: 7px;
			background-color: #fff;
			position: absolute;
			bottom: -19px;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: rotate(45deg);
		}
		.trapezoid {
			bottom: 0;
			width: 165px;
			position: absolute;
			height: 145px;
			background: rgb(182, 38, 38);
		}
	}
}
</style>
